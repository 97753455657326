.chartPlaceHolder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    color: white;
}

.chartPlaceHolder canvas {
    width: 340px !important;
    height: 170px !important;
}

.chartLegend {
    position: absolute;
    text-align: center;
}


.chartPlaceHolder p {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;


}
