body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: "Commissioner";
    src: local("static"),
    url("./TEMPLATE/assets/fonts/static/Commissioner-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Commissioner";
    src: local("static"),
    url("./TEMPLATE/assets/fonts/static/Commissioner-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Commissioner";
    src: local("static"),
    url("./TEMPLATE/assets/fonts/static/Commissioner-Black.ttf") format("truetype");
    font-weight: 900;
}
.BoxesContainer_advertisement_v5__1YsNR{
    background:#ef6272!important;
}

.BoxesContainer_advertisement_v5__1YsNR img{
     /*   height: auto!important;
        min-width:120px!important*/
}

.BoxesContainer_advertisement_v4__327bm{
    background-color:#7ab2cd!important;
}
.BoxesContainer_advertisement_v4__327bm img{
  /*  background: #bfeaff!important;
    padding: 10px;
    border-radius: 150px;
    height: 150px!important;
    width:auto!important; */
}

.BoxesContainer_advertisement_v3__1u9UO {
    background-color: #63B32E!important;
}
.BoxesContainer_advertisement_v3__1u9UO img {
    /* background: #d3ff97!important;
     border-radius: 180px;
     height: 130px!important;
     width:130px!important;
     padding:25px 5px;
     margin-top:-20px; */
     height:auto!important;
 }
 .BoxesContainer_advertisement_v2__1Tlzk {
    background-color: #9180FF!important;
}

.BoxesContainer_advertisement_v2__1Tlzk img{
 /*   background: #cbc2ff!important;
    padding: 10px;
    border-radius: 140px;
    height: 140px!important;
    width:auto!important; */
}