.welcome_placeholder {
    padding: 30px 20px;
    line-height: 1.5;
    font-weight: 700;
    color: white;
}

.welcome_placeholder > div {
    position: absolute;
    bottom: 0;
    left: 5px;
}

.appsPlaceholder,
.projectInfo {
    display: flex;
    align-items: center;
    justify-content: center;
}


.projectInfo {
    justify-content: flex-start;
    width: 306px;
    height: 48px;
    margin: 5px 5px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: black;

    cursor: pointer;
}

.projectInfo > img {
    margin-left: 10px;
    border-radius: 50%;
    background-color: white;
}

.projectInfo > p {
    padding-left: 10px;

}
.logo_normal{
    max-width: 306px!important;
    height: auto!important;
    margin: 10px 0!important;
    border-radius: 0!important;
    position: absolute;
    bottom: 110px;
    left: 10px;
}

.appsPlaceholder > img {
    padding: 5px;

    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .welcome_placeholder {
        text-align: center;
    }


    .welcome_placeholder > div {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .welcome_placeholder {
        padding: 20px 20px 10px;
    }
}
