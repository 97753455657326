.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  padding: 20px;
  padding-right: 30px;
  width: fit-content;
  padding-left: 6px;
}

.gm-style-iw-chr {
  width: 10px;
  position: absolute;
  top: 2px;
  right: 30px;
}

.gm-style .gm-style-iw-c {
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}
