.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    min-height: 50px;
    max-height: 50px;
    opacity: unset !important;
    transform: unset !important;
}

.title {
    padding: 0px 17px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.title a {
    text-decoration: none;
    color: black;
}

.info_icon {
    transform: translate(0px, 2px);
}

.triggerTooltip:hover :nth-child(2) {
    max-height: 500px;
    padding: 10px;
    transition: padding 0s linear, max-height 0.25s ease-in;
    transition-delay: 0.25s;
}

.lastElementPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
}

.dragAndDropTooltip {
    min-width: 105px;
    transform: translate(-47px, 37px);
}

.triggerTooltip:hover :nth-child(2).dragAndDropTooltip {
    max-height: 500px;
    padding: 10px;
    transition: padding 0s linear, max-height 0.25s ease-in;
    transition-delay: 0.5s;
}

.triggerTooltip:active :nth-child(2).dragAndDropTooltip {
    display: none;
}

/*.lastElementPlaceholder > i {*/
/*    cursor: grab !important;*/
/*}*/

/*.lastElementPlaceholder > i:active {*/
/*    cursor: grabbing;*/
/*}*/
